import { LitElementAttribute } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { Hook_get_emptyDisposable, Hook_getContext_343DAFF1 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { establishAuthentication } from "./Auth.js";
import { startImmediate } from "./fable_modules/fable-library.4.5.0/Async.js";
import { initAtlantisSessionUrls } from "./Utils.js";
import { Lit_get_nothing } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";

export const InitApp = (new LitElementAttribute("init-app")).Decorate(function () {
    (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = false);
        return Promise.resolve(void 0);
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        establishAuthentication();
        startImmediate(initAtlantisSessionUrls());
        if ((sessionStorage["archive_id"]) == null) {
            window.location.href = "/atlas.html";
        }
        else {
            window.location.href = "/map.html";
        }
        return Hook_get_emptyDisposable();
    });
    return Lit_get_nothing();
});

